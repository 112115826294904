<template>
  <div class="content">
    <customer-detail></customer-detail>
  </div>
</template>

<script>
import CustomerDetail from "@/components/customer-detail/CustomerDetail"

export default {
  name: "Index",
  components: {
    CustomerDetail
  }
}
</script>

<style lang="scss">
.content {
  color: #404040;
}
</style>
